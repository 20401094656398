<div class="row" *ngIf="isLoading | async">
  <div class="col-12 text-center">
    <app-loading-state></app-loading-state>
  </div>
</div>
<div class="row mt-3" *ngIf="(isLoading | async) === false && (harvesters | async).length === 0">
  <div class="col-12 text-center">
    <app-empty-state text="No harvesters available" [icon]="faTractor"></app-empty-state>
  </div>
</div>
<div class="row mb-3" *ngFor="let harvester of harvesters | async | slice: (page - 1) * pageSize : page * pageSize; trackBy: trackBy">
  <div class="col-12">
    <app-harvester-card [harvester]="harvester" (updatedHarvester)="updateHarvesters({ bustCache: true })"></app-harvester-card>
  </div>
</div>
<div class="d-grid footer-grid">
  <div class="pagination-grid-item">
    <ngb-pagination
      *ngIf="(harvesters | async).length > pageSize"
      [(page)]="page"
      [pageSize]="pageSize"
      [collectionSize]="(harvesters | async).length"
      [maxSize]="5"
      [rotate]="true"
      [boundaryLinks]="true">
    </ngb-pagination>
  </div>
  <div style="margin-left: auto" *ngIf="showItemsPerPageSelection | async">
    <div class="input-group" style="flex-wrap: nowrap">
      <label class="input-group-text page-size-select-label">
        Items per page
      </label>
      <select  class="form-select page-size-select" [(ngModel)]="pageSize">
        <option *ngFor="let currPageSize of pageSizes" [ngValue]="currPageSize" class="page-size-select-option">{{currPageSize}}</option>
      </select>
    </div>
  </div>
</div>
