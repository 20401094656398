<div class="row">
  <app-stats-card class="col-md-12 col-lg-6"></app-stats-card>
  <app-pool-history class="col-md-12 col-lg-6"></app-pool-history>
</div>
<div class="row" style="margin-bottom: 1em" *ngIf="hasNotices">
  <div class="col-12">
    <h4>
      {{snippetService.getSnippet('dashboard-component.latest-notices.title')}}:
    </h4>
    <div class="row">
      <ul>
        <li *ngFor="let notice of noticesInTheLastWeekOrLess">
          <span style="font-size: 0.8em">{{getFormattedDate(notice.date)}}</span>
          <div [innerHTML]="getTranslatedNoticeText(notice)"></div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12 col-xl-4 col-xxl-4 col-xxxl-9">
    <app-top-accounts></app-top-accounts>
  </div>
  <div class="col-lg-12 col-xl-8 col-xxl-8 col-xxxl-15">
    <app-blocks-won limit=10></app-blocks-won>
  </div>
</div>
<app-payouts limit=5></app-payouts>
