<h5 class="settings-modal-text">Generate a login link</h5>
<div class="row">
  <div class="col-12">
    <div class="alert alert-info" role="alert" style="font-size: 1.1rem">
      The login link is a one time use link to login and authenticate another device. It will expire after 10 minutes.
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="alert alert-warning" role="alert" style="font-size: 1.1rem">
      Do not share this link with anyone as it will fully authenticate them!
    </div>
  </div>
</div>
<div class="row mt-2 mb-3" *ngIf="(loginLink | async) !== undefined">
  <div class="col-12 text-center">
    <qrcode
      [qrdata]="loginLink | async"
      [elementType]="'canvas'"
      [errorCorrectionLevel]="'M'"
      [imageSrc]="'https://static.foxypool.io/assets/icon/fox/icon-72x72.png'"
      [imageHeight]="50"
      [imageWidth]="50"
      [margin]="0"
      [scale]="1"
      [width]="400"
    ></qrcode>
  </div>
</div>
<div class="row mb-4" *ngIf="(loginLink | async) !== undefined">
  <div class="col-12">
    <div class="d-flex justify-content-center gap-2">
      <a [href]="loginLink | async" target="_blank">Login Link</a>
      <div
        ngbTooltip="Copy login link"
        ngxClipboard
        [cbContent]="loginLink | async"
        (cbOnSuccess)="onCopied()"
        style="cursor: pointer"
      >
        <fa-icon [icon]="copyIcon" [ngStyle]="copyIconStyle"></fa-icon>
      </div>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <button type="button" class="btn btn-primary w-100" (click)="generateLoginToken()" [disabled]="isGenerating">
      <span *ngIf="isGenerating">Generating</span>
      <span *ngIf="!isGenerating">Generate login link</span>
      <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="isGenerating" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
    </button>
  </div>
</div>

