<div class="row mb-2" *ngIf="hasWonBlocksWithEffort | async">
  <div class="col-md-12">
    <div echarts [options]="chartOptions" [merge]="chartUpdateOptions" theme="default" class="farmer-won-blocks-chart"></div>
  </div>
</div>
<div class="row justify-content-end">
  <div class="col-auto" *ngIf="hasGigahorseDevFeeBlocksAndEnoughWonBlocks$ | async">
    <div class="ps-2 pt-1 pe-2 pb-1 border rounded-1" [ngClass]="effectiveGigahorseDevFeeBoxClasses" style="font-size: 0.875rem">
      <span class="fw-lighter pe-1">Effective Gigahorse Dev Fee:</span><span class="fw-bold">{{effectiveGigahorseDevFee$ | async}}</span>
      <fa-icon
        [icon]="faInfoCircle"
        ngbTooltip="The effective Gigahorse Dev Fee is calculated based on the number of blocks you won which went to the Gigahorse Dev Fee address compared to the total number of blocks you won."
        class="ps-1 info-icon"
      ></fa-icon>
    </div>
  </div>
  <div class="col-auto">
    <button *ngIf="hasWonBlocksObservable | async" type="button" class="btn btn-sm" [ngClass]="exportCsvButtonClasses" style="float: right" (click)="exportCsv()">{{snippetService.getSnippet('download-csv-button.title')}}</button>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="table-responsive-lg">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              <div class="date-header-container">
                <span>{{snippetService.getSnippet('blocks-won-component.header.date')}}</span>
                <fa-icon [icon]="faExchangeAlt" class="toggle-date-formatting-icon" (click)="toggleDateFormatting()"></fa-icon>
              </div>
            </th>
            <th scope="col">{{snippetService.getSnippet('blocks-won-component.header.height')}}</th>
            <th scope="col">{{snippetService.getSnippet('blocks-won-component.header.effort')}}</th>
            <th scope="col">Farmer Reward</th>
            <th scope="col"><span ngbTooltip="The time it took your farm from receiving the signage point till a block was created">Farm Time</span></th>
            <th scope="col">Remarks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="isLoading && (hasWonBlocksObservable | async) === false">
            <td colspan="6" style="text-align: center">
              <app-loading-state></app-loading-state>
            </td>
          </tr>
          <tr *ngIf="!isLoading && (hasWonBlocksObservable | async) === false">
            <td colspan="6" style="text-align: center; padding-top: 1rem">
              <app-empty-state [text]="snippetService.getSnippet('blocks-won-component.no-blocks-won-yet')" [icon]="faCubes"></app-empty-state>
            </td>
          </tr>
          <tr *ngFor="let block of wonBlocksObservable | async | slice: (page - 1) * pageSize : page * pageSize; trackBy: trackBlockByHash">
            <td>{{getBlockDate(block)}}</td>
            <td *ngIf="(statsService.poolConfig$ | async)?.blockExplorerBlockUrlTemplate"><a href="{{getBlockExplorerBlockLink(block)}}" target="_blank" style="color: #b45bff">{{block.height}}</a></td>
            <td *ngIf="!(statsService.poolConfig$ | async)?.blockExplorerBlockUrlTemplate">{{block.height}}</td>
            <td><span [class]="getEffortColor(block)">{{getBlockEffort(block)}}</span></td>
            <td>
              <span ngbTooltip="Now: {{ratesService.getValuesInFiatFormatted(getFarmerRewardAmount(block))}} | At receipt: {{ratesService.getValueInHistoricalFiatFormatted(getFarmerRewardAmount(block), block.historicalRate)}}">
                {{getFarmerRewardFormatted(block)}}
              </span>
            </td>
            <td *ngIf="block.farmTimeInSeconds === undefined">N/A</td>
            <td *ngIf="block.farmTimeInSeconds !== undefined"><span [ngStyle]="{color: getFarmTimeColor(block.farmTimeInSeconds)}">{{formatFarmTime(block.farmTimeInSeconds)}} sec</span></td>
            <td *ngIf="block.remarks === undefined || block.remarks.length === 0">
              <span class="badge remark-pill background-color-light-green">OK</span>
            </td>
            <td *ngIf="block.remarks !== undefined && block.remarks.length > 0">
            <span *ngFor="let remark of block.remarks; let last = last;">
              <span class="badge remark-pill"
                    [ngClass]="getRemarkClasses(remark)"
                    ngbTooltip="{{getRemarkTooltip(remark)}}"
              >{{getRemarkDetail(remark)}}</span>
              <span *ngIf="!last">&nbsp;</span>
            </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ngb-pagination
  *ngIf="(wonBlocksObservable | async).length > pageSize"
  class="d-flex justify-content-center"
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="(wonBlocksObservable | async).length"
  [maxSize]="5"
  [rotate]="true"
  [boundaryLinks]="true">
</ngb-pagination>
