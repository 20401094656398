<ng-template #confirmationModal let-dismiss="dismiss" let-close="close">
  <div class="modal-header">
    <h4 class="modal-title settings-modal-text">{{title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss(false)"></button>
  </div>
  <div class="modal-body">
    {{description}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="dismiss(false)">Cancel</button>
    <button type="button" class="btn" [ngClass]="confirmButtonClass" (click)="close(true)">{{confirmButtonText}}</button>
  </div>
</ng-template>
