<div class="row">
  <div class="col-12" *ngIf="(accountService.haveAccountIdentifier$ | async) === false">
    <div class="d-flex justify-content-center">
      <input
        type="text"
        class="form-control input-field"
        style="width: 32em;margin-right: 0.5rem;"
        [placeholder]="accountIdentifierInputPlaceholder"
        [(ngModel)]="accountIdentifierInput"
        (keyup.enter)="login()"
      >
      <button class="btn btn-success" style="margin-left: 0.5rem;" (click)="login()" [disabled]="!accountIdentifierInput">
        <fa-icon *ngIf="accountService.isLoading$ | async" [icon]="faCircleNotch" animation="spin"></fa-icon>
        <span *ngIf="accountService.isLoading$ | async"> {{snippetService.getSnippet('my-farmer-component.login-button.label-logging-in')}}</span>
        <span *ngIf="(accountService.isLoading$ | async) === false">{{snippetService.getSnippet('my-farmer-component.login-button.label')}}</span>
      </button>
    </div>
  </div>
  <div class="col-12" *ngIf="(accountService.haveAccountIdentifier$ | async) && (accountService.haveAccount$ | async) === false && accountService.isLoading$ | async">
    <div class="d-flex justify-content-center">
      <app-loading-state></app-loading-state>
    </div>
  </div>
  <div class="col-12" *ngIf="(accountService.haveAccountIdentifier$ | async) && (accountService.haveAccount$ | async)">
    <div *ngIf="isPoolMember && (accountHasNeverSubmittedAPartial || shouldShowRejoinedAlert || shouldShowNewAccountFarmingAlert)" class="row mb-1 ps-2 pe-2">
      <ngb-alert *ngIf="accountHasNeverSubmittedAPartial || shouldShowRejoinedAlert" type="primary" class="mb-1 text-center no-border-color" [dismissible]="false">
        {{accountHasJoinedAlertMessage}}
      </ngb-alert>
      <ngb-alert *ngIf="shouldShowNewAccountFarmingAlert" type="primary" class="mb-1 text-center no-border-color" (closed)="hideNewAccountInfoAlert()">
        You just started farming, it will take 24h to show accurate estimations based on farm size.
      </ngb-alert>
    </div>
    <div class="row mb-1">
      <div class="col-auto my-auto pe-0 profile-picture-image-wrapper" *ngIf="accountImageUrl$ | async">
        <img [src]="accountImageUrl$ | async" class="img-fluid rounded profile-picture-image">
      </div>
      <div class="col col-md">
        <div>
          <span *ngIf="accountService.account.name !== undefined" class="mb-0 fw-light fs-2">{{accountService.account.name}}</span>
          <ng-template #badgeContent>
            <div *ngFor="let badge of badges; trackBy: trackBadgesBySrc" class="pt-1 pb-1 d-flex align-items-center">
              <img [ngSrc]="badge.imgSrcPath"
                   [alt]="badge.description"
                   loading="lazy"
                   height="64"
                   width="64"
              >
              <div class="ms-3 d-inline-block">{{badge.description}}</div>
            </div>
          </ng-template>
          <div
            class="d-inline-block pt-2"
            style="cursor: pointer"
            [ngbPopover]="badgeContent"
            [autoClose]="'outside'"
          >
            <img *ngFor="let badge of badges; let isFirst = first; trackBy: trackBadgesBySrc"
                 [ngSrc]="badge.imgSrcPath"
                 [alt]="badge.description"
                 [ngbTooltip]="badge.description"
                 [ngClass]="(!isFirst || hasName) ? 'ms-2' : ''"
                 loading="lazy"
                 height="32"
                 width="32"
                 style="margin-top: -0.8em"
            >
          </div>
        </div>
        <div class="fw-light fs-5 text-break">{{accountService.accountIdentifier$ | async}}</div>
      </div>
      <div class="col-12 col-md-auto my-auto action-box" *ngIf="!accountService.haveAuthToken">
        <button *ngIf="showAuthenticationButton" class="btn btn-success" (click)="authenticate()">{{snippetService.getSnippet('my-farmer-component.manage-account-card.authenticate')}}</button>
        <a *ngIf="showAuthenticationDocsLinkButton" class="btn btn-success text-decoration-none" [href]="authDocsUrl" target="_blank">Authenticate</a>
      </div>
      <div class="col-12 col-md-auto my-auto action-box" *ngIf="accountService.haveAuthToken">
        <button *ngIf="canRejoinPool" class="btn btn-success me-2" (click)="rejoinPool()">{{snippetService.getSnippet('my-farmer-component.manage-account-card.action.rejoin-pool')}}</button>
        <button class="btn" [ngClass]="settingsButtonClasses" (click)="openSettingsModal()">Settings</button>
      </div>
    </div>
    <div class="row row-cols-xxl-5" [ngClass]="rowClasses">
      <div class="p-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl col-xxxl-4 col-xxxxl col-xxxxxl">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>{{snippetService.getSnippet('my-farmer-component.pending-balance-card.title')}}</h5>
          </div>
          <div class="card-body text-align-center card-font-size card-body-with-progress-bar">
            <span ngbTooltip="{{ratesService.getValuesInFiatFormatted(pendingRounded)}}">{{pendingRounded}} {{statsService.ticker$ | async}}</span>
          </div>
          <ng-template #pendingProgressTooltipContent>
            <span class="color-green">{{pendingProgressRaw.toFixed(2)}}%</span> of the minimum payout reached.
            <span *ngIf="pendingProgress < 100 && isCollateralFilledOrNonExistent">The minimum payout will be reached <span class="color-green">{{timeTillMinimumPayoutReached}}</span>.</span>
            <span *ngIf="pendingProgress === 100">Your pending balance will be paid out in the next payout <span class="color-green">{{timeTillNextPayout}}</span></span>
          </ng-template>
          <ngb-progressbar
            class="card-progress-bar"
            [type]="pendingProgress < 100 ? 'primary' : 'success'"
            [striped]="false"
            [animated]="false"
            [value]="pendingProgress"
            [ngbTooltip]="pendingProgressTooltipContent"
          ></ngb-progressbar>
        </div>
      </div>
      <div class="p-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl col-xxxl-4 col-xxxxl col-xxxxxl">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>Estimated reward</h5>
            <fa-icon
              [icon]="faInfoCircle"
              ngbTooltip="The estimated {{estimatedRewardIntervalLabel}} reward is calculated based on your average effective capacity and the pools average daily reward per PiB over the past 7 days, which is shown on the dashboard."
              class="header-info-icon"
            ></fa-icon>
          </div>
          <div class="card-body text-align-center card-font-size">
            <span ngbTooltip="{{ratesService.getValuesInFiatFormatted(estimatedScaledReward)}}">{{estimatedScaledReward}} {{statsService.ticker$ | async}}</span>
            <span class="reward-interval ms-1" (click)="cycleRewardInterval()" ngbTooltip="Click to toggle between daily, weekly or monthly estimates">({{estimatedRewardIntervalLabel}})</span>
          </div>
        </div>
      </div>
      <div class="p-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl col-xxxl-4 col-xxxxl col-xxxxxl">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>Effective Capacity</h5>
            <fa-icon
              [icon]="faInfoCircle"
              [ngbTooltip]="snippetService.getSnippet('my-farmer-component.ec-card.help')"
              class="header-info-icon"
            ></fa-icon>
          </div>
          <div class="card-body text-align-center card-font-size">
            {{getFormattedCapacity(accountService.account.ec)}}
            <div class="d-inline-block" style="font-size: 14.4px">
              (<div class="d-inline-block" ngbTooltip="You hold {{ecShare}}% of the pools capacity">{{ecShare}}%</div>
              <div class="d-inline-block" *ngIf="rank">, <div class="d-inline-block" ngbTooltip="You are ranked #{{rank}} in the pool">#{{rank}}</div></div>
              <div class="d-inline-block" ngbTooltip="Your Distribution ratio is {{accountService.account.distributionRatio}}">, {{accountService.account.distributionRatio}}</div>)
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="hasChiaDashboardShareKey$ | async" class="p-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl col-xxxl-4 col-xxxxl col-xxxxxl">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>Reported Capacity</h5>
            <fa-icon
              [icon]="faInfoCircle"
              ngbTooltip="The reported raw and effective capacities are aggregated from all available harvesters connected to your chia-dashboard."
              class="header-info-icon"
            ></fa-icon>
          </div>
          <div class="card-body text-align-center card-font-size">
            <app-loading-state *ngIf="chiaDashboardService.isInitialLoading$ | async" width="1" height="1" colorClass="text-light"></app-loading-state>
            <span *ngIf="(chiaDashboardService.isInitialLoading$ | async) === false && (reportedRawCapacity$ | async) !== null && (reportedEffectiveCapacity$ | async) !== null" ngbTooltip="Reported raw / effective capacity">{{reportedRawCapacity$ | async}} / {{reportedEffectiveCapacity$ | async}}</span>
          </div>
        </div>
      </div>
      <div class="p-1 col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl col-xxxl-4 col-xxxxl col-xxxxxl">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>{{snippetService.getSnippet('my-farmer-component.last-partial-accepted-card.title')}}</h5>
          </div>
          <div class="card-body text-align-center card-font-size">
            <app-loading-state *ngIf="(lastAcceptedPartialAt$ | async) === null" width="1" height="1" colorClass="text-light"></app-loading-state>
            <span>{{lastAcceptedPartialAt$ | async}}</span>
          </div>
        </div>
      </div>
      <div class="p-1 col-12 col-md-8 col-lg-6 col-xl-6 col-xxl-6 order-xxl-last col-xxxl-12 col-xxxxl-8 col-xxxxxl-6" [class.col-sm-12]="(hasChiaDashboardShareKey$ | async) === false" [class.col-sm-6]="hasChiaDashboardShareKey$ | async">
        <div class="card">
          <div class="card-header text-align-center d-flex justify-content-between">
            <span></span> <!-- Dummy element for justify-content-between -->
            <h5>{{snippetService.getSnippet('my-farmer-component.payout-address-card.title')}}</h5>
            <app-loading-state *ngIf="isLoadingPayoutAddressBalance | async" width="1" height="1" colorClass="text-light"></app-loading-state>
            <div class="d-inline-block" *ngIf="(isLoadingPayoutAddressBalance | async) === false" [ngbTooltip]="ratesService.getValuesInFiatFormatted(payoutAddressBalance | async)">{{payoutAddressBalance | async}} {{statsService.ticker$ | async}}</div>
          </div>
          <div class="card-body text-align-center card-font-size ellipsis">
            <fa-icon *ngIf="payoutAddressWarning !== undefined" [ngbTooltip]="payoutAddressWarning" [icon]="faTriangleExclamation" class="text-warning me-2"></fa-icon>
            <a href="{{getBlockExplorerAddressLink(accountService.account.payoutAddress)}}" target="_blank" style="color: #b45bff;">{{accountService.account.payoutAddress}}</a>
          </div>
        </div>
      </div>
      <div class="p-1 col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl col-xxxl-4 col-xxxxl col-xxxxxl" [class.col-xxl-2]="hasChiaDashboardShareKey$ | async" [class.col-md-4]="hasChiaDashboardShareKey$ | async">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>Current Effort</h5>
            <fa-icon
              [icon]="faInfoCircle"
              ngbTooltip="The current effort shows the effort since the last won block based on your average effective capacity. The effort is given in percent, where 100% equals your estimated time to win."
              class="header-info-icon"
            ></fa-icon>
          </div>
          <div class="card-body text-align-center card-font-size">
            <span [class]="getEffortColor(currentEffort)">{{currentEffortFormatted}}</span>
          </div>
        </div>
      </div>
      <div class="p-1 col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2 col-xxxl-4 col-xxxxl col-xxxxxl">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>Average Effort</h5>
            <fa-icon
              [icon]="faInfoCircle"
              ngbTooltip="The average effort is aggregated over your last 100 block win efforts"
              class="header-info-icon"
            ></fa-icon>
          </div>
          <div class="card-body text-align-center card-font-size">
            <span [class]="averageEffortColorClass | async">{{averageEffortFormatted | async}}</span>
          </div>
        </div>
      </div>
      <div class="p-1 col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2 col-xxxl-4 col-xxxxl col-xxxxxl" *ngIf="accountService.account.collateral">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>{{snippetService.getSnippet('my-farmer-component.collateral-balance-card.title')}}</h5>
            <fa-icon
              [icon]="faInfoCircle"
              [ngbTooltip]="snippetService.getSnippet('my-farmer-component.collateral-balance-card.help')"
              class="header-info-icon"
            ></fa-icon>
          </div>
          <div class="card-body text-align-center card-font-size card-body-with-progress-bar">
            <span ngbTooltip="{{ratesService.getValuesInFiatFormatted(collateralRounded)}}">{{collateralRounded}} {{statsService.ticker$ | async}}</span>
          </div>
          <ng-template #collateralProgressTooltipContent>
            <span class="color-green">{{collateralProgressRaw.toFixed(2)}}%</span> of your collateral reached.
            <span *ngIf="collateralProgress < 100">The collateral will be reached <span class="color-green">{{timeTillCollateralReached}}</span>.</span>
          </ng-template>
          <ngb-progressbar
            class="card-progress-bar"
            [type]="collateralProgress < 100 ? 'primary' : 'success'"
            [striped]="false"
            [animated]="false"
            [value]="collateralProgress"
            [ngbTooltip]="collateralProgressTooltipContent"
          ></ngb-progressbar>
        </div>
      </div>
      <div class="p-1 col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-2 col-xxxl-4 col-xxxxl col-xxxxxl">
        <div class="card">
          <div class="card-header text-align-center">
            <h5>{{snippetService.getSnippet('my-farmer-component.difficulty-card.title')}}</h5>
          </div>
          <div class="card-body text-align-center card-font-size">
            {{accountService.account.difficulty}}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="p-2 col-md-12">
        <div class="row m-0">
          <ul ngbNav #nav="ngbNav" [activeId]="selectedNavTab | async" class="nav-tabs col-12 col-sm" [ngClass]="{'nav-tabs-no-border': showDurationSelection$ | async}">
            <li ngbNavItem="stats" [destroyOnHide]="true">
              <a class="text-decoration-none" ngbNavLink routerLink="." [fragment]="null"><h5>{{snippetService.getSnippet('my-farmer-component.tabs.stats.label')}}</h5></a>
              <ng-template ngbNavContent>
                <div class="p-1 col-md-12">
                  <div echarts [options]="ecChartOptions" [merge]="ecChartUpdateOptions" theme="default" class="ec-chart"></div>
                </div>
                <div class="row mt-2 text-align-center ms-0 me-0">
                  <h5 style="font-weight: bold">Shares</h5>
                </div>
                <div class="row justify-content-center ms-0 me-0 shares-box-container">
                  <div class="col-12 col-sm-4 col-xxxxl-6 shares-box">
                    <div class="p-2 border border-primary rounded">
                      <h5 class="mb-1 fw-lighter">Valid Shares</h5>
                      <h4>{{totalValidShares | async}} <span class="shares-box-percentage">({{totalValidSharesPercentage | async}}%)</span></h4>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 col-xxxxl-6 shares-box">
                    <div class="p-2 border border-primary rounded">
                      <h5 class="mb-1 fw-lighter">Stale Shares</h5>
                      <h4 [ngClass]="staleSharesColorClasses | async">{{totalStaleShares | async}} <span class="shares-box-percentage">({{totalStaleSharesPercentage | async}}%)</span></h4>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 col-xxxxl-6 shares-box">
                    <div class="p-2 border border-primary rounded">
                      <h5 class="mb-1 fw-lighter">Invalid Shares</h5>
                      <h4 [ngClass]="invalidSharesColorClasses | async">{{totalInvalidShares | async}} <span class="shares-box-percentage">({{totalInvalidSharesPercentage | async}}%)</span></h4>
                    </div>
                  </div>
                </div>
                <div class="p-1 col-md-12">
                  <div echarts [options]="sharesChartOptions" [merge]="sharesChartUpdateOptions" (chartLegendSelectChanged)="onSharesChartLegendSelectChanged($event)" theme="default" class="shares-chart"></div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="rewards" [destroyOnHide]="true">
              <a class="text-decoration-none" ngbNavLink routerLink="." fragment="rewards"><h5>Rewards</h5></a>
              <ng-template ngbNavContent>
                <div class="p-1 col-md-12">
                  <app-farmer-balance-changes
                    [coinConfig]="statsService.coinConfig"
                    [selectedCurrencyObservable]="selectedCurrencyObservable"
                  ></app-farmer-balance-changes>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="recent-payouts" [destroyOnHide]="true">
              <a class="text-decoration-none" ngbNavLink routerLink="." fragment="recent-payouts"><h5>{{snippetService.getSnippet('my-farmer-component.tabs.recent-payouts.label')}}</h5></a>
              <ng-template ngbNavContent>
                <div class="p-1 col-md-12">
                  <app-farmer-payout-history
                    [coinConfig]="statsService.coinConfig"
                    [payoutDateFormattingObservable]="payoutDateFormattingObservable"
                    [selectedCurrencyObservable]="selectedCurrencyObservable"
                  ></app-farmer-payout-history>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="won-blocks" [destroyOnHide]="true">
              <a class="text-decoration-none" ngbNavLink routerLink="." fragment="won-blocks"><h5>{{snippetService.getSnippet('my-farmer-component.tabs.won-blocks.label')}}</h5></a>
              <ng-template ngbNavContent>
                <div class="p-1 col-md-12">
                  <app-farmer-won-blocks
                    [isLoading]="isAccountLoading | async"
                    [wonBlocksObservable]="accountService.accountWonBlocks.asObservable()"
                  ></app-farmer-won-blocks>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="harvesters" [destroyOnHide]="true">
              <a class="text-decoration-none" ngbNavLink routerLink="." fragment="harvesters"><h5>Harvesters</h5></a>
              <ng-template ngbNavContent>
                <div class="p-1 col-md-12">
                  <app-farmer-harvesters></app-farmer-harvesters>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="partials" [destroyOnHide]="true">
              <a class="text-decoration-none" ngbNavLink routerLink="." fragment="partials"><h5>Partials</h5></a>
              <ng-template ngbNavContent>
                <div class="p-1 col-md-12">
                  <app-farmer-partials></app-farmer-partials>
                </div>
              </ng-template>
            </li>
          </ul>
          <div class="col-12 col-sm-auto d-flex justify-content-end duration-selection" *ngIf="showHistoricalDurationSelection | async">
            <div class="btn-group" style="display: inline-block" role="group" aria-label="Historical duration selection">
              <ng-container *ngFor="let duration of possibleDurations">
                <input type="radio" class="btn-check" name="options-base" id="duration-radio-{{duration}}" autocomplete="off" [(ngModel)]="selectedHistoricalStatsDuration" [value]="duration">
                <label class="btn" [ngClass]="radioSelectionButtonClasses" for="duration-radio-{{duration}}">{{duration.toUpperCase()}}</label>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-sm-auto d-flex justify-content-end duration-selection" *ngIf="showAccountPayoutsChartSizeSelection$ | async">
            <div class="btn-group" style="display: inline-block" role="group" aria-label="Account payout chart size selection">
              <ng-container *ngFor="let size of possibleAccountPayoutsChartSizes">
                <input type="radio" class="btn-check" name="radio-btn" id="duration-radio-apc-{{size}}" autocomplete="off" [(ngModel)]="selectedAccountPayoutsChartSize" [value]="size">
                <label class="btn" [ngClass]="radioSelectionButtonClasses" for="duration-radio-apc-{{size}}">{{size}}</label>
              </ng-container>
            </div>
          </div>
        </div>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>
<app-settings-modal></app-settings-modal>
<app-authentication-modal></app-authentication-modal>
