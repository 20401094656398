<div class="card">
  <div class="card-header d-flex justify-content-between align-items-center">
    <div>
      <h5 class="d-flex align-items-center gap-1">
        <span *ngIf="(statusDotColorClass$ | async) !== null" class="dot" [ngClass]="statusDotColorClass$ | async" ngbTooltip="Last updated {{relativeLastUpdated$ | async}}"></span>
        <editable [enabled]="canEditName" (save)="updateName()" (cancel)="cancelNameUpdate()">
          <ng-template viewMode>
            <div class="d-flex harvester-name-or-peer-id-container" [ngClass]="{ 'is-editable': canEditName }">
              <div class="harvester-name-or-peer-id-view-mode">{{nameOrPeerIdSlug}}</div>
              <div class="ps-1 visible-on-hover"><fa-icon [icon]="faPencil"></fa-icon></div>
            </div>
          </ng-template>

          <ng-template editMode>
            <input editableOnEnter editableOnEscape class="form-control input-field harvester-name-input" type="text" [formControl]="nameControl"/>
          </ng-template>
        </editable>
        <span *ngIf="(status$ | async) !== null" class="badge status-pill" [ngClass]="statusColorClass$ | async" [ngbTooltip]="statusTooltip$ | async">{{status$ | async}}</span>
      </h5>
      <div *ngIf="hasName">{{peerIdSlug}}</div>
    </div>
    <div class="d-flex justify-content-between align-items-center gap-3">
      <select class="form-select input-field" [ngModel]="chartMode | async" (ngModelChange)="setChartMode($event)">
        <option [value]="ChartMode.shares">Shares</option>
        <option [value]="ChartMode.proofTimes">Proof times</option>
      </select>
      <fa-icon
        *ngIf="accountService.haveAuthToken"
        class="highlight"
        [icon]="faEllipsisV"
        size="2xl"
        style="cursor: pointer;"
        (click)="openSettingsModal()"
      ></fa-icon>
    </div>
  </div>
  <div class="card-body harvester-card">
    <div class="row" [ngClass]="rowColumnClasses">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Valid Shares</div>
          <app-loading-state *ngIf="isLoading | async" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
          <h5 *ngIf="(isLoading | async) === false">{{totalValidShares | async}} ({{totalValidSharesPercentage | async}}%)</h5>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Stale Shares</div>
          <app-loading-state *ngIf="isLoading | async" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
          <h5 *ngIf="(isLoading | async) === false" [ngClass]="staleSharesColorClasses | async">{{totalStaleShares | async}} ({{totalStaleSharesPercentage | async}}%)</h5>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Average EC</div>
          <app-loading-state *ngIf="isLoading | async" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
          <h5 *ngIf="(isLoading | async) === false">{{averageEc | async}}</h5>
        </div>
      </div>
      <div *ngIf="(reportedRawCapacity$ | async) !== null" class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Raw Capacity</div>
          <h5>{{reportedRawCapacity$ | async}}</h5>
        </div>
      </div>
      <div *ngIf="(reportedEffectiveCapacity$ | async) !== null" class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Reported Capacity</div>
          <h5>{{reportedEffectiveCapacity$ | async}}</h5>
        </div>
      </div>
      <div *ngIf="(plotCount$ | async) !== null" class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Plots</div>
          <h5>{{plotCount$ | async}}</h5>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Average proof time</div>
          <app-loading-state *ngIf="isLoading | async" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
          <h5 *ngIf="(isLoading | async) === false" [ngClass]="averageProofTimeInSecondsColorClass | async">{{averageProofTimeInSeconds | async}}</h5>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1">
        <div class="p-2 border border-primary rounded text-center">
          <div class="mb-1">Last Partial accepted</div>
          <h5>{{lastAcceptedPartialAt}}</h5>
        </div>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasChiaVersion">
        <app-version-info
          versionName="Chia Version"
          [versionString]="fullChiaVersionString"
          [versionUpdateInfo]="chiaVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasFastFarmerVersion">
        <app-version-info
          versionName="Fast Farmer Version"
          [versionString]="fastFarmerVersion"
          [versionUpdateInfo]="fastFarmerVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasGigahorseFastFarmerVersion">
        <app-version-info
          versionName="Fast Farmer (GH) Version"
          [versionString]="gigahorseFastFarmerVersion"
          [versionUpdateInfo]="gigahorseFastFarmerVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasLiteFarmerVersion">
        <app-version-info
          versionName="Lite Farmer Version"
          [versionString]="liteFarmerVersion"
          [versionUpdateInfo]="liteFarmerVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasOgVersion">
        <app-version-info
          versionName="OG Version"
          [versionString]="ogVersion"
          [versionUpdateInfo]="ogVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasGigahorseVersion">
        <app-version-info
          versionName="Gigahorse Version"
          [versionString]="gigahorseVersion"
          [versionUpdateInfo]="gigahorseVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasDrPlotterVersion">
        <app-version-info
          versionName="DrPlotter Version"
          [versionString]="drPlotterVersion"
          [versionUpdateInfo]="drPlotterVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasFoxyFarmerVersion">
        <app-version-info
          versionName="Foxy-Farmer Version"
          [versionString]="foxyFarmerVersion"
          [versionUpdateInfo]="foxyFarmerVersionUpdateInfo"
        ></app-version-info>
      </div>
      <div class="col-6 col-sm-6 col-md-4 col-lg-3 col-xl col-xxl col-xxxl col-xxxxl col-xxxxxl p-1" *ngIf="hasFoxyGhFarmerVersion">
        <app-version-info
          versionName="Foxy-GH-Farmer Version"
          [versionString]="foxyGhFarmerVersion"
          [versionUpdateInfo]="foxyGhFarmerVersionUpdateInfo"
        ></app-version-info>
      </div>
    </div>
    <div class="row" *ngIf="showSharesChart | async">
      <div class="col-12">
        <div echarts [options]="sharesChartOptions" [merge]="sharesChartUpdateOptions" theme="default" class="shares-chart"></div>
      </div>
    </div>
    <div class="row" *ngIf="showProofTimesChart | async">
      <div class="col-12" *ngIf="hasProofTimes | async">
        <div echarts [options]="proofTimesChartOptions" [merge]="proofTimesChartUpdateOptions" theme="default" class="proof-times-chart"></div>
      </div>
      <div class="col-12 text-center mt-3" *ngIf="(hasProofTimes | async) === false && (isLoadingProofTimes | async) === false">
        <app-empty-state text="No proof times recorded in the last {{selectedHistoricalStatsDuration}}" [icon]="faReceipt"></app-empty-state>
      </div>
      <div class="col-12 text-center mt-3" *ngIf="isLoadingProofTimes | async">
        <app-loading-state [height]="3" [width]="3"></app-loading-state>
      </div>
    </div>
  </div>
</div>
<app-harvester-settings-modal [harvester]="harvester" (updatedHarvester)="harvesterWasUpdated()"></app-harvester-settings-modal>
