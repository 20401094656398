<h5>{{snippetService.getSnippet('payouts-component.title')}}</h5>
<div class="table-responsive-lg">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">
          <div class="date-header-container">
            <span>{{snippetService.getSnippet('payouts-component.headers.date')}}</span>
            <fa-icon [icon]="faExchangeAlt" class="toggle-date-formatting-icon" (click)="toggleDateFormatting()"></fa-icon>
          </div>
        </th>
        <th scope="col">{{snippetService.getSnippet('payouts-component.headers.transaction')}}</th>
        <th scope="col">{{snippetService.getSnippet('payouts-component.headers.amount')}}</th>
        <th scope="col">{{snippetService.getSnippet('payouts-component.headers.state')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="(statsService.recentPayouts$ | async) === null">
        <td colspan="4" style="text-align: center">
          <app-loading-state></app-loading-state>
        </td>
      </tr>
      <tr *ngIf="(statsService.recentPayouts$ | async) !== null && recentPayouts.length === 0">
        <td colspan="4" style="text-align: center; padding-top: 1rem">
          <app-empty-state [text]="snippetService.getSnippet('payouts-component.no-payouts-sent-yet')" [icon]="faMoneyCheck"></app-empty-state>
        </td>
      </tr>
      <tr *ngFor="let payout of recentPayouts; trackBy: trackPayoutBy">
        <td class="no-wrap">{{getPayoutDate(payout)}}</td>
        <td class="no-wrap">
      <span *ngFor="let coinId of getCoinIdsForPayout(payout); let lastCoinId = last">
        <a *ngIf="(statsService.poolConfig$ | async)?.blockExplorerCoinUrlTemplate" href="{{getBlockExplorerCoinLink(coinId)}}" target="_blank" style="color: #b45bff">{{coinId}}</a>
        <span *ngIf="!(statsService.poolConfig$ | async)?.blockExplorerCoinUrlTemplate">{{coinId}}</span>
        <span *ngIf="!splitMultiPayoutsByBreak && !lastCoinId">, </span>
        <br *ngIf="splitMultiPayoutsByBreak && !lastCoinId">
      </span>
        </td>
        <td class="no-wrap">
          <span (click)="toggleShowPayout(payout)" ngbTooltip="{{ratesService.getValuesInFiatFormatted(getTotalPayout(payout.transactions), payout.historicalRate)}}">
            {{getTotalPayout(payout.transactions)}} {{statsService.ticker$ | async}}
          </span>
          <span *ngIf="showPayout(payout._id)">
        <span *ngFor="let data of getAddressAmountPairs(payout._id)">
          <br>
          {{data.address}}: <span ngbTooltip="{{ratesService.getValuesInFiatFormatted(data.amount, payout.historicalRate)}}">{{data.amount}} {{statsService.ticker$ | async}}</span>
        </span>
      </span>
        </td>
        <td class="no-wrap">{{getPaymentState(payout)}}</td>
      </tr>
    </tbody>
  </table>
</div>
