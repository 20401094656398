<div style="clear: both;"></div>
<div class="footer-spacer"></div>
<footer>
  <div style="display: flex; justify-content: center; flex-wrap: wrap">
    <div style="padding-right: 1em; padding-top: 0.8rem; text-align: center;" class="footer-logo">
      <img ngSrc="https://static.foxypool.io/assets/icon/fox.svg" width="70" height="70" alt="" [priority]="true">
      <br>
      <span style="font-weight: 300;font-size: 2em;">Foxy</span>
    </div>

    <div class="verticalLine footer-logo" style="padding-right: 2em"></div>

    <div style="display: flex;">
      <div style="padding-right: 1em; padding-left: 1em">
        <div style="justify-content: space-evenly">
          <span style="font-weight: 300; font-size: larger;">Product</span>
          <hr>
        </div>
        <div style="justify-content: space-evenly">
          <a [href]="gettingStartedUrl" target="_blank" class="link highlight">
            Getting Started
          </a>
          <br>
          <a [href]="downloadUrl" target="_blank" class="link highlight">
            Download
          </a>
          <br>
          <a href="https://status.foxypool.io" target="_blank" class="link highlight">
            Status
          </a>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <div style="padding-right: 1em; padding-left: 1em">
        <div style="justify-content: space-evenly">
          <span style="font-weight: 300; font-size: larger;">Resources</span>
          <hr>
        </div>
        <div style="display: flex">
          <div style="padding-right: 1em">
            <a href="https://foxypool.io" target="_blank" class="link highlight">
              Pool Overview
            </a>
            <br>
            <a href="https://docs.foxypool.io/proof-of-spacetime/foxy-pool/" target="_blank" class="link highlight">
              Documentation
            </a>
            <br>
            <a href="https://api-docs.foxypool.io" target="_blank" class="link highlight">
              Developers
            </a>
          </div>
        </div>
      </div>
    </div>

    <div style="display: flex;">
      <div style="padding-left: 1em">
        <div style="justify-content: space-evenly">
          <span style="font-weight: 300; font-size: larger;">Community</span>
          <hr>
        </div>
        <div style="justify-content: space-evenly">
          <a href="https://discord.gg/foxypool" target="_blank">
            <fa-icon [icon]="faDiscord" class="fa highlight"></fa-icon>
          </a>
          <a href="https://t.me/foxypool" target="_blank" style="padding-left: 10px">
            <fa-icon [icon]="faTelegram" class="fa highlight"></fa-icon>
          </a>
          <a href="https://x.com/foxypool" target="_blank" style="padding-left: 10px">
            <fa-icon [icon]="faXTwitter" class="fa highlight"></fa-icon>
          </a>
          <a href="https://github.com/foxypool" target="_blank" style="padding-left: 10px">
            <fa-icon [icon]="faGithub" class="fa highlight"></fa-icon>
          </a>
          <br>
          <a href="mailto:contact@foxypool.io" class="link highlight">
            contact&#64;foxypool.io
          </a>
        </div>
      </div>
    </div>

  </div>
  <div style="text-align: center; padding-bottom: 4px; padding-top: 1em">
    &copy; 2019-{{currentYear}} Foxy
  </div>
</footer>
