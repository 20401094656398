<ng-template #settingsModal let-dismiss="dismiss">
  <div class="modal-header">
    <h4 class="modal-title settings-modal-text">Harvester Settings</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-sm-3 settings-nav">
        <div class="row h-100">
          <div class="col">
            <ul ngbNav #nav="ngbNav" class="nav-pills">
              <li ngbNavItem="notifications" [destroyOnHide]="true">
                <a class="text-decoration-none" ngbNavLink>Notifications</a>
                <ng-template ngbNavContent>
                  <app-update-harvester-notification-settings [harvester]="harvester" (updatedHarvester)="harvesterWasUpdated()"></app-update-harvester-notification-settings>
                </ng-template>
              </li>
            </ul>
          </div>
          <div class="col-auto align-self-end">
            <button type="button" class="btn btn-danger w-100" (click)="confirmHarvesterDeletion()">
              <span *ngIf="isDeleting">Deleting</span>
              <span *ngIf="!isDeleting">Delete this Harvester</span>
              <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="isDeleting" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-9">
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</ng-template>
<app-confirmation-modal
  title="Delete this Harvester?"
  description="Are you sure you want to delete this Harvester? This action can not be reverted."
  confirmButtonText="Delete"
  confirmButtonClass="btn-danger"
></app-confirmation-modal>
