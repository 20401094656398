<h5 class="settings-modal-text">Notification settings</h5>
<div class="row">
  <div class="col-12">
    <div class="form-group">
      <label for="ecLastHourThreshold" class="form-label">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="enableCurrentEcChangeNotifications" [(ngModel)]="areCurrentEcChangeNotificationsEnabled">
          <label class="form-check-label settings-modal-text bigger-text" for="enableCurrentEcChangeNotifications" style="display: inline">
            Notify when the current EC drops below
          </label>
        </div>
      </label>
      <div class="input-group has-validation">
        <input
          type="number"
          class="form-control"
          id="ecLastHourThreshold"
          aria-describedby="ecLastHourThresholdFeedback"
          [placeholder]="currentEcLastHourThreshold"
          [min]="0"
          [class.is-invalid]="!isValidEcLastHourThreshold"
          [(ngModel)]="newEcLastHourThreshold"
        >
        <label>
          <select class="form-select custom-select dropdown" [(ngModel)]="selectedCurrentEcCapacityDenominator">
            <option *ngFor="let denominator of possibleCapacityDenominators" [value]="denominator">{{denominator}}</option>
          </select>
        </label>
        <div id="ecLastHourThresholdFeedback" class="invalid-feedback">
          The current EC threshold must be a number greater than or equal to 0.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <div class="form-group">
      <label for="ecLastHourThreshold" class="form-label">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="enableAverageEcChangeNotifications" [(ngModel)]="areAverageEcChangeNotificationsEnabled">
          <label class="form-check-label settings-modal-text bigger-text" for="enableAverageEcChangeNotifications" style="display: inline">
            Notify when the average EC drops below
          </label>
        </div>
      </label>
      <div class="input-group has-validation">
        <input
          type="number"
          class="form-control"
          id="averageEcThreshold"
          aria-describedby="averageEcThresholdFeedback"
          [placeholder]="currentAverageEcThreshold"
          [min]="0"
          [class.is-invalid]="!isValidAverageEcThreshold"
          [(ngModel)]="newAverageEcThreshold"
        >
        <label>
          <select class="form-select custom-select dropdown" [(ngModel)]="selectedAverageEcCapacityDenominator">
            <option *ngFor="let denominator of possibleCapacityDenominators" [value]="denominator">{{denominator}}</option>
          </select>
        </label>
        <div id="averageEcThresholdFeedback" class="invalid-feedback">
          The average EC threshold must be a number greater than or equal to 0.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <div class="form-group">
      <label for="harvesterOfflineDuration" class="form-label">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="enableHarvesterOfflineNotifications" [(ngModel)]="areHarvesterOfflineNotificationsEnabled">
          <label class="form-check-label settings-modal-text bigger-text" for="enableHarvesterOfflineNotifications" style="display: inline">
            Notify when a Harvester does not send any partials for
          </label>
        </div>
      </label>
      <div class="duration-slider-wrapper">
        <ngx-slider id="harvesterOfflineDuration" [(value)]="newHarvesterOfflineDurationInMinutes" [options]="harvesterOfflineDurationOptions"></ngx-slider>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12">
    <div class="form-check form-switch" style="padding-bottom: 1rem">
      <input class="form-check-input" type="checkbox" role="switch" id="enableBlockWonNotifications" [(ngModel)]="areBlockWonNotificationsEnabled">
      <label class="form-check-label settings-modal-text bigger-text" for="enableBlockWonNotifications" style="display: inline">
        Notify on block win
      </label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="form-check form-switch" style="padding-bottom: 1rem">
      <input class="form-check-input" type="checkbox" role="switch" id="enablePayoutAddressChangeNotifications" [(ngModel)]="arePayoutAddressChangeNotificationsEnabled">
      <label class="form-check-label settings-modal-text bigger-text" for="enablePayoutAddressChangeNotifications" style="display: inline">
        Notify on payout address change
      </label>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12" >
    <div class="alert alert-info" role="alert" style="font-size: 1.1rem">
      You can subscribe and unsubscribe to your accounts notification using your Discord or Telegram account, see <a href="https://docs.foxypool.io/proof-of-spacetime/guides/subscribing-to-notifications/" target="_blank">this guide</a> on how to do that.
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <button type="button" class="btn btn-success w-100" (click)="updateNotificationSettings()" [disabled]="!canUpdateNotificationSettings">
      <span *ngIf="accountService.isUpdatingAccount">Saving</span>
      <span *ngIf="!accountService.isUpdatingAccount">Apply changes</span>
      <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="accountService.isUpdatingAccount" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
    </button>
  </div>
</div>
