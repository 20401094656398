<div class="table-responsive-lg">
  <table class="table">
    <thead>
    <tr>
      <th scope="col">
        <div class="date-header-container">
          <span>Date</span>
          <fa-icon [icon]="faExchangeAlt" class="toggle-date-formatting-icon" (click)="toggleDateFormatting()"></fa-icon>
        </div>
      </th>
      <th scope="col">Type</th>
      <th scope="col">Amount</th>
      <th scope="col">Value (Now)</th>
      <th scope="col">Value (At receipt)</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="isLoadingInitial | async">
      <td colspan="5" style="text-align: center">
        <app-loading-state></app-loading-state>
      </td>
    </tr>
    <tr *ngIf="(isLoadingInitial | async) === false && (balanceChanges | async)?.length === 0">
      <td colspan="5" style="text-align: center; padding-top: 1rem">
        <app-empty-state text="No rewards tracked yet" [icon]="faBarsStaggered"></app-empty-state>
      </td>
    </tr>
    <tr *ngFor="let balanceChange of balanceChanges | async; trackBy: trackBy">
      <td>{{balanceChange.formattedDate}}</td>
      <td>
        <span
          *ngIf="balanceChange.type === AccountBalanceChangeType.blockRewardShare"
          ngbTooltip="For height {{balanceChange.meta.block}}, {{(balanceChange.meta.percentage * 100).toFixed(6)}}% of all shares"
        >
          <fa-icon [icon]="faHandshake"></fa-icon> Block Share
        </span>
        <span
          *ngIf="balanceChange.type === AccountBalanceChangeType.blockWinnerReward"
          ngbTooltip="For height {{balanceChange.meta.block}}"
        >
          <fa-icon [icon]="faTrophy"></fa-icon> Block Reward
        </span>
        <span *ngIf="balanceChange.type === AccountBalanceChangeType.payout">
          <fa-icon [icon]="faCoins"></fa-icon> Payout
        </span>
        <span *ngIf="balanceChange.type === AccountBalanceChangeType.payoutRollback">
          <fa-icon [icon]="faCoins"></fa-icon> Payout Rollback
        </span>
        <span
          *ngIf="balanceChange.type === AccountBalanceChangeType.poolFee"
          ngbTooltip="For height {{balanceChange.meta.block}}"
        >
          <fa-icon [icon]="faHandHoldingDollar"></fa-icon> Pool fee
        </span>
      </td>
      <td class="no-wrap">{{balanceChange.amountFormatted}} {{statsService.ticker$ | async}}</td>
      <td class="no-wrap">{{balanceChange.fiatAmountNowFormatted}}</td>
      <td class="no-wrap">{{balanceChange.fiatAmountAtReceiptFormatted}}</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="d-grid footer-grid">
  <div class="pagination-grid-item">
    <ngb-pagination
      *ngIf="total > pageSize"
      class="d-flex justify-content-center"
      [(page)]="page"
      [pageSize]="pageSize"
      [collectionSize]="total"
      [maxSize]="5"
      [rotate]="true"
      [boundaryLinks]="true"
      (pageChange)="onPageChange()">
      <ng-template ngbPaginationNumber let-p>
        <div class="d-flex flex-nowrap gap-1">
          {{ p }}
          <app-loading-state *ngIf="p === page && (isLoading | async)" width="1" height="1" colorClass="text-light"></app-loading-state>
        </div>
      </ng-template>
    </ngb-pagination>
  </div>
  <div style="margin-left: auto" *ngIf="showItemsPerPageSelection | async">
    <div class="input-group" style="flex-wrap: nowrap">
      <label class="input-group-text page-size-select-label">
        Items per page
      </label>
      <select  class="form-select page-size-select" [(ngModel)]="pageSize">
        <option *ngFor="let currPageSize of pageSizes" [ngValue]="currPageSize" class="page-size-select-option">{{currPageSize}}</option>
      </select>
    </div>
  </div>
</div>
