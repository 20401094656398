<ng-template #authenticationModal let-dismiss="dismiss">
  <div class="modal-header">
    <h4 class="modal-title settings-modal-text">{{snippetService.getSnippet('authentication-modal.title')}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <label for="messageToSign" class="form-label settings-modal-text">{{snippetService.getSnippet('authentication-modal.message-to-sign')}}:</label>
        <div id="messageToSign" class="input-group">
          <input type="text" class="form-control" [value]="message" disabled>
          <div class="input-group-text"
               ngbTooltip="{{snippetService.getSnippet('general.copy')}}"
               ngxClipboard
               [cbContent]="message"
               (cbOnSuccess)="copied()"
               style="cursor: pointer">
            <fa-icon [icon]="copyIcon" [ngStyle]="copyIconStyle"></fa-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="padding-top: 1rem">
      <div class="col-12">
        <input type="text" class="form-control" placeholder="{{snippetService.getSnippet('authentication-modal.signature-placeholder')}}" [(ngModel)]="signature">
      </div>
    </div>
    <div class="row" style="padding-top: 1rem">
      <div class="col-12">
        <div class="alert alert-info" role="alert">
          <div [innerHTML]="snippetService.getSnippet('authentication-modal.authentication-info', statsService.coinConfig.cliCommandPrefix, statsService.coinConfig.hdKeyPoolPublicKeyPath, message)"></div>
        </div>
      </div>
      <div class="col-12 settings-modal-text">
        <span [innerHTML]="snippetService.getSnippet('authentication-modal.authentication-guide', docsAuthenticationGuideUrl)"></span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="dismiss('cancel')">{{snippetService.getSnippet('general.cancel')}}</button>
    <button type="button" class="btn btn-success" (click)="authenticate()" [disabled]="accountService.isAuthenticating || !signature">
      <span *ngIf="accountService.isAuthenticating">{{snippetService.getSnippet('authentication-modal.authenticating')}}</span>
      <span *ngIf="!accountService.isAuthenticating">{{snippetService.getSnippet('authentication-modal.authenticate')}}</span>
      <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="accountService.isAuthenticating" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
    </button>
  </div>
</ng-template>
