<div class="row">
  <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xxxl-7 small-col-padding">
    <div class="card">
      <div class="card-header card-text-medium card-header-large">
        {{snippetService.getSnippet('stats-card-component.current-effort')}}
        <fa-icon
          [icon]="faInfoCircle"
          [ngbTooltip]="snippetService.getSnippet('stats-card-component.current-effort.help')"
          placement="bottom"
          class="header-info-icon"
        ></fa-icon>
      </div>
      <div class="card-body card-body-large">
        <span class="card-text card-text-large">
          <span *ngIf="!areRewardStatsLoading && !arePoolStatsLoading && !areAccountStatsLoading" [class]="getEffortColor(currentEffort)">{{currentEffortFormatted}}</span>
          <app-loading-state *ngIf="areRewardStatsLoading || arePoolStatsLoading || areAccountStatsLoading" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
        </span>
      </div>
    </div>
  </div>
  <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xxxl-7 small-col-padding">
    <div class="card">
      <div class="card-header card-text-medium card-header-large">
        {{snippetService.getSnippet('stats-card-component.average-effort')}}
        <fa-icon
          [icon]="faInfoCircle"
          [ngbTooltip]="snippetService.getSnippet('stats-card-component.average-effort.help')"
          placement="bottom"
          class="header-info-icon"
        ></fa-icon>
      </div>
      <div class="card-body card-body-large">
        <span class="card-text card-text-large">
          <span *ngIf="!areRewardStatsLoading" [class]="getEffortColor(averageEffort)">{{averageEffortFormatted}}</span>
          <app-loading-state *ngIf="areRewardStatsLoading" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
        </span>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xxxl-10 small-col-padding">
    <div class="card">
      <div class="card-header card-text-medium card-header-large">
        {{snippetService.getSnippet('stats-card-component.daily-reward')}}
        <fa-icon
          [icon]="faInfoCircle"
          [ngbTooltip]="snippetService.getSnippet('stats-card-component.daily-reward.help')"
          placement="bottom"
          class="header-info-icon"
        ></fa-icon>
      </div>
      <div class="card-body card-body-large">
        <span class="card-text card-text-large">
          <span *ngIf="!areRewardStatsLoading && !isPoolConfigLoading" placement="bottom" ngbTooltip="{{ratesService.getValuesInFiatFormatted(dailyRewardPerPiB)}} / PiB">{{dailyRewardPerPiBFormatted}} {{statsService.ticker$ | async}} / PiB</span>
          <app-loading-state *ngIf="areRewardStatsLoading || isPoolConfigLoading" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
        </span>
      </div>
    </div>
  </div>
  <div class="col-6 col-sm-6 col-md-2 col-lg-3 col-xxxl-5 small-col-padding order-lg-1 order-xxxl-0">
    <div class="card">
      <div class="card-header card-text-medium card-header-large">
        {{snippetService.getSnippet('stats-card-component.farmers')}}
      </div>
      <div class="card-body card-body-large">
        <span class="card-text card-text-large">
          <span *ngIf="!areAccountStatsLoading">{{(statsService.accountStats$ | async)?.accountsWithShares ?? 0}}</span>
          <app-loading-state *ngIf="areAccountStatsLoading" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
        </span>
      </div>
    </div>
  </div>
  <div class="col-6 col-sm-6 col-md-3 col-lg-6 col-xxxl-6 small-col-padding order-lg-0 order-xxxl-1">
    <div class="card">
      <div class="card-header card-text-medium card-header-large">
        {{snippetService.getSnippet('stats-card-component.pool-ec')}}
      </div>
      <div class="card-body card-body-large">
        <span class="card-text card-text-large">
          <span *ngIf="!areAccountStatsLoading">{{getFormattedCapacityFromGiB((statsService.accountStats$ | async)?.ecSum ?? 0)}}</span>
          <app-loading-state *ngIf="areAccountStatsLoading" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
        </span>
      </div>
    </div>
  </div>
  <div class="col-6 col-sm-6 col-md-3 col-lg-3 col-xxxl-6 small-col-padding order-lg-2">
    <div class="card">
      <div class="card-header card-text-medium card-header-large">
        {{snippetService.getSnippet('stats-card-component.height')}}
      </div>
      <div class="card-body card-body-large">
        <span class="card-text card-text-large">
          <span *ngIf="!arePoolStatsLoading">{{heightInfo$ | async}}</span>
          <app-loading-state *ngIf="arePoolStatsLoading" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
        </span>
      </div>
    </div>
  </div>
  <div class="col-6 col-sm-6 col-md-4 col-lg-6 col-xxxl-7 small-col-padding order-lg-3">
    <div class="card">
      <div class="card-header card-text-medium card-header-large">
        {{snippetService.getSnippet('stats-card-component.network-capacity')}}
      </div>
      <div class="card-body card-body-large">
        <span class="card-text card-text-large">
          <span *ngIf="!arePoolStatsLoading">{{getFormattedCapacityFromTiB(networkSpaceInTiB)}}</span>
           <app-loading-state *ngIf="arePoolStatsLoading" [height]="2" [width]="2" fontSize="14px"></app-loading-state>
        </span>
      </div>
    </div>
  </div>
</div>
