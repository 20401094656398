<h5 class="settings-modal-text">Notification settings</h5>
<div class="row mt-3">
  <div class="col-12">
    <div class="form-group">
      <label for="harvesterOfflineDuration" class="form-label">
        <div class="form-check form-switch">
          <input class="form-check-input" [ngClass]="{ 'inherited-switch': isInheritedAreOfflineNotificationsEnabled }" type="checkbox" role="switch" id="enableHarvesterOfflineNotifications" [(ngModel)]="areOfflineNotificationsEnabled">
          <label class="form-check-label settings-modal-text bigger-text" for="enableHarvesterOfflineNotifications" style="display: inline">
            Notify when this Harvester does not send any partials for
          </label>
        </div>
      </label>
      <div class="duration-slider-wrapper" [ngClass]="{ inherited: isInheritedOfflineDurationInMinutes }">
        <ngx-slider id="harvesterOfflineDuration" [(value)]="newOfflineDurationInMinutes" [options]="harvesterOfflineDurationOptions"></ngx-slider>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div class="col-12" >
    <div class="alert alert-info bigger-text" role="alert">
      You can subscribe and unsubscribe to your accounts notification using your Discord or Telegram account, see <a href="https://docs.foxypool.io/proof-of-spacetime/guides/subscribing-to-notifications/" target="_blank">this guide</a> on how to do that.
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <button type="button" class="btn btn-success w-100" (click)="updateNotificationSettings()" [disabled]="!canUpdateNotificationSettings">
      <span *ngIf="isUpdating">Saving</span>
      <span *ngIf="!isUpdating">Apply changes</span>
      <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="isUpdating" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
    </button>
  </div>
</div>
