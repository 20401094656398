<h5 class="settings-modal-text">{{snippetService.getSnippet('leave-pool-modal.title')}}</h5>
<div class="row">
  <div class="col-12" *ngIf="haveCollateral">
    <div class="form-check checkbox-lg" style="padding-bottom: 1rem">
      <input class="form-check-input" type="checkbox" id="leaveForEverCheckbox" [(ngModel)]="leaveForEver">
      <label class="form-check-label settings-modal-text" for="leaveForEverCheckbox">
        {{ snippetService.getSnippet('leave-pool-modal.leave-forever') }}
      </label>
    </div>
    <div class="alert alert-danger bigger-text" role="alert" *ngIf="leaveForEver">
      {{snippetService.getSnippet('leave-pool-modal.collateral-warning')}}
    </div>
  </div>
  <div class="col-12" >
    <div class="alert alert-info bigger-text" role="alert" *ngIf="!leaveForEver">
      {{snippetService.getSnippet('leave-pool-modal.rejoin-info')}}
    </div>
  </div>
  <div class="col-12" >
    <div class="alert alert-warning bigger-text" role="alert">
      <span [innerHTML]="snippetService.getSnippet('leave-pool-modal.read-leave-guide-info', leavePoolGuideUrl)"></span>
    </div>
  </div>
</div>
<div class="row mt-2">
  <div class="col-12">
    <button type="button" class="btn btn-danger w-100" (click)="leaveThePool()" [disabled]="accountService.isUpdatingAccount || accountService.isLeavingPool">
      <span *ngIf="accountService.isLeavingPool">{{snippetService.getSnippet('leave-pool-modal.leaving')}}</span>
      <span *ngIf="!accountService.isLeavingPool">{{snippetService.getSnippet('leave-pool-modal.leave')}}</span>
      <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="accountService.isLeavingPool" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
    </button>
  </div>
</div>

