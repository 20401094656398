<div>
  <h6 class="settings-modal-text">Configure a minimum payout amount</h6>
  <div class="row">
    <div class="col-12">
      <div class="input-group has-validation">
        <input
          type="number"
          class="form-control"
          id="minimumPayout"
          aria-describedby="minimumPayoutFeedback"
          [placeholder]="poolConfigMinimumPayout"
          [min]="poolConfigMinimumPayout"
          [class.is-invalid]="!isValidMinimumPayout"
          [(ngModel)]="newMinimumPayout"
        >
        <span class="input-group-text">{{ticker}}</span>
        <div id="minimumPayoutFeedback" class="invalid-feedback">
          Minimum payout must be greater or equal to the pools minimum payout amount of {{poolConfigMinimumPayout}} {{ticker}}.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-2">
  <h6 class="settings-modal-text">
    <div class="d-flex gap-2">
      <span>Payout multiples of</span>
      <fa-icon
        [icon]="faInfoCircle"
        ngbTooltip="Only payout multiples of the supplied amount. For example with an input of 0.5 only exactly 0.5, 1, 1.5 etc are paid out, the remainder is kept in the pending balance till the next payout."
      ></fa-icon>
    </div>
  </h6>
  <div class="row">
    <div class="col-12">
      <div class="input-group has-validation">
        <input
          type="number"
          class="form-control"
          id="payoutMultiplesOf"
          aria-describedby="payoutMultiplesOfFeedback"
          [min]="poolConfigMinimumPayout"
          [class.is-invalid]="!isValidPayoutMultiplesOf"
          [(ngModel)]="newPayoutMultiplesOf"
        >
        <span class="input-group-text">{{ticker}}</span>
        <div id="payoutMultiplesOfFeedback" class="invalid-feedback">
          Payout multiples of must be greater or equal to the pools minimum payout amount of {{poolConfigMinimumPayout}} {{ticker}}.
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <button type="button" class="btn btn-success w-100" (click)="updatePayoutOptions()" [disabled]="!canUpdatePayoutOptions">
      <span *ngIf="accountService.isUpdatingAccount">Applying</span>
      <span *ngIf="!accountService.isUpdatingAccount">Apply changes</span>
      <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="accountService.isUpdatingAccount" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
    </button>
  </div>
</div>
