<nav class="navbar navbar-expand-xl sticky-navbar" [ngClass]="navbarClasses">
  <a class="navbar-brand text-decoration-none">
    <img ngSrc="https://static.foxypool.io/assets/icon/fox.svg" width="34" height="34" alt="" [priority]="true">
    <span class="pool-title">{{poolTitle}}</span>
  </a>
  <button class="navbar-toggler" type="button" (click)="toggleMenuCollapse()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav me-auto">
      <li class="nav-item">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="onTabButtonClick()">
            {{snippetService.getSnippet('header-component.button.dashboard')}}
          </button>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/my-farmer" routerLinkActive="active" (click)="onTabButtonClick()">
            {{snippetService.getSnippet('header-component.button.my-farmer')}}
          </button>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/farmers" [queryParams]="{ page: '1' }" [class.active]="isLinkActive('/farmers')" (click)="onTabButtonClick()">
            Farmers
          </button>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/blocks-won" routerLinkActive="active" (click)="onTabButtonClick()">
            {{snippetService.getSnippet('header-component.button.blocks-won')}}
          </button>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/payouts" routerLinkActive="active" (click)="onTabButtonClick()">
            {{snippetService.getSnippet('header-component.button.payouts')}}
          </button>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/info" routerLinkActive="active" (click)="onTabButtonClick()">
            {{snippetService.getSnippet('header-component.button.info')}}
          </button>
        </a>
      </li>
      <li class="nav-item" *ngIf="(statsService.poolConfig$ | async)?.notices.length > 0">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/notices" routerLinkActive="active" (click)="onTabButtonClick()">
            {{snippetService.getSnippet('header-component.button.notices')}}
          </button>
        </a>
      </li>
      <li class="nav-item" *ngIf="(statsService.poolStats$ | async)?.events.length > 0">
        <a class="nav-link">
          <button type="button" class="btn btn-outline-primary no-wrap" routerLink="/events" routerLinkActive="active" (click)="onTabButtonClick()">
            {{snippetService.getSnippet('header-component.button.events')}}
          </button>
        </a>
      </li>
    </ul>
    <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
      <div class="header-row-item" *ngIf="showLogoutButton">
        <button class="btn btn-outline-danger logout-button" (click)="accountService.logout()">{{snippetService.getSnippet('header-component.button.logout')}}</button>
      </div>
      <div class="header-row-item" *ngIf="showJoinPoolButton">
        <a class="btn btn-primary text-decoration-none no-wrap" [href]="joinPoolUrl" target="_blank">
          Join Pool
        </a>
      </div>
      <div class="header-row-item" *ngIf="ratesService.currencies.length > 0">
        <app-currency-selector></app-currency-selector>
      </div>
      <div class="input-group header-row-item search-input">
        <input
          class="form-control input-field"
          type="search"
          [(ngModel)]="accountSearchInput"
          [placeholder]="accountSearchInputPlaceholder"
          (keyup.enter)="search()"
          aria-label="Search"
        >
        <div *ngIf="(isSearchingAccount$ | async) === false" class="input-group-text search-button" (click)="search()">
          <fa-icon [icon]="searchIcon" class="color-green"></fa-icon>
        </div>
        <div *ngIf="isSearchingAccount$ | async" class="input-group-text is-searching-account-spinner">
          <fa-icon [icon]="faCircleNotch" animation="spin" class="color-green"></fa-icon>
        </div>
      </div>
      <div ngbDropdown display="dynamic" class="d-inline-block header-row-item" *ngIf="otherPools.length > 0">
        <button class="btn btn-outline-light other-pools-button" id="poolsDropdown" ngbDropdownToggle>
          {{snippetService.getSnippet('header-component.button.other-pools')}}
        </button>
        <div ngbDropdownMenu aria-labelledby="poolsDropdown">
          <div *ngFor="let pool of otherPools; let i = index" [attr.data-index]="i">
            <h6 *ngIf="!getPool(i - 1) || getPool(i - 1).algorithm !== pool.algorithm" class="dropdown-header">{{pool.algorithm}}</h6>
            <a class="text-decoration-none" ngbDropdownItem href="{{pool.url}}" target="_blank">{{pool.name}}</a>
            <div *ngIf="getPool(i + 1) && getPool(i + 1).algorithm !== pool.algorithm" class="dropdown-divider"></div>
          </div>
        </div>
      </div>
      <div class="header-row-item theme-selector">
        <div class="theme-switcher-container">
          <input type="checkbox" id="theme-switch" class="theme-switcher-checkbox" [(ngModel)]="showMoonInThemeSwitcher"/>
          <label for="theme-switch" class="theme-switcher-label">
            <fa-icon [icon]="faMoon" class="theme-switcher-moon-icon" size="xl"></fa-icon>
            <fa-icon [icon]="faSun" class="theme-switcher-sun-icon" size="xl"></fa-icon>
            <span class="theme-switcher-ball"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</nav>
