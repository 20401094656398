<div>
  <h6 class="settings-modal-text">
    <div class="d-flex gap-2">
      <a href="https://dashboard.chia.foxypool.io" target="_blank">Chia-Dashboard</a> Share Key
      <fa-icon
        [icon]="faInfoCircle"
        ngbTooltip="To obtain the share key, share your dashboard via the profile page and copy the last part of the url, it will look like this: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx."
      ></fa-icon>
    </div>
  </h6>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="form">
        <div class="d-flex gap-2" [class.is-invalid]="form.invalid">
          <input
            type="text"
            class="form-control"
            id="chiaDashboardShareKey"
            formControlName="chiaDashboardShareKey"
            aria-describedby="chiaDashboardShareKeyFeedback"
          >
          <app-loading-state *ngIf="isValidatingShareKey" width="2" height="2"></app-loading-state>
        </div>
        <div id="chiaDashboardShareKeyFeedback" class="invalid-feedback">
          The provided share key is not valid.
        </div>
      </form>
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <button type="button" class="btn btn-success w-100" (click)="updateIntegrations()" [disabled]="!canUpdateIntegrations">
      <span *ngIf="accountService.isUpdatingAccount">Applying</span>
      <span *ngIf="!accountService.isUpdatingAccount">Apply changes</span>
      <fa-icon [icon]="faCircleNotch" animation="spin" *ngIf="accountService.isUpdatingAccount" style="margin-top: 5px; margin-left: 0.8em"></fa-icon>
    </button>
  </div>
</div>
