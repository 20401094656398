<ng-template #settingsModal let-dismiss="dismiss">
  <div class="modal-header">
    <h4 class="modal-title settings-modal-text">Settings</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-sm-3 settings-nav">
        <ul ngbNav #nav="ngbNav" class="nav-pills">
          <li ngbNavItem="update-account" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Account</a>
            <ng-template ngbNavContent>
              <app-update-account></app-update-account>
            </ng-template>
          </li>
          <li ngbNavItem="minimum-payout" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Payouts</a>
            <ng-template ngbNavContent>
              <app-update-payout-options></app-update-payout-options>
            </ng-template>
          </li>
          <li ngbNavItem="blocks" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Blocks</a>
            <ng-template ngbNavContent>
              <app-update-block-settings></app-update-block-settings>
            </ng-template>
          </li>
          <li ngbNavItem="rewards" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Rewards</a>
            <ng-template ngbNavContent>
              <app-update-reward-settings></app-update-reward-settings>
            </ng-template>
          </li>
          <li ngbNavItem="difficulty" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Difficulty</a>
            <ng-template ngbNavContent>
              <app-update-difficulty></app-update-difficulty>
            </ng-template>
          </li>
          <li ngbNavItem="notifications" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Notifications</a>
            <ng-template ngbNavContent>
              <app-update-notification-settings></app-update-notification-settings>
            </ng-template>
          </li>
          <li ngbNavItem="integrations" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Integrations</a>
            <ng-template ngbNavContent>
              <app-integration-settings></app-integration-settings>
            </ng-template>
          </li>
          <li ngbNavItem="login-link" [destroyOnHide]="true">
            <a class="text-decoration-none" ngbNavLink>Login Link</a>
            <ng-template ngbNavContent>
              <app-generate-login-link></app-generate-login-link>
            </ng-template>
          </li>
          <li ngbNavItem="leave-pool" [destroyOnHide]="true" *ngIf="canLeavePool">
            <a class="text-decoration-none" ngbNavLink>Leave Pool</a>
            <ng-template ngbNavContent>
              <app-leave-pool></app-leave-pool>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="col-12 col-sm-9">
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</ng-template>
