<div style="display: flex;flex-direction: row;justify-content: space-between;">
  <h5>
    {{snippetService.getSnippet('blocks-won-component.title')}}
    <span *ngIf="limit">| {{snippetService.getSnippet('blocks-won-component.blocks-won-in-the-last-24h', numberOfBlocksWonInLast24h$ | async)}}</span>
  </h5>
</div>
<div class="table-responsive-lg">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">
          <div class="date-header-container">
            <span>{{snippetService.getSnippet('blocks-won-component.header.date')}}</span>
            <fa-icon [icon]="faRightLeft" class="toggle-date-formatting-icon" (click)="toggleDateFormatting()"></fa-icon>
          </div>
        </th>
        <th scope="col">{{snippetService.getSnippet('blocks-won-component.header.height')}}</th>
        <th scope="col">Netspace</th>
        <th scope="col"><span ngbTooltip="Distribution ratio">DR</span></th>
        <th scope="col">{{snippetService.getSnippet('blocks-won-component.header.effort')}}</th>
        <th scope="col">{{snippetService.getSnippet('blocks-won-component.header.winner')}}</th>
        <th scope="col">{{snippetService.getSnippet('blocks-won-component.header.reward')}}</th>
        <th scope="col">State</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="(statsService.rewardStats$ | async) === null">
        <td colspan="8" style="text-align: center">
          <app-loading-state></app-loading-state>
        </td>
      </tr>
      <tr *ngIf="(statsService.rewardStats$ | async)?.recentlyWonBlocks.length === 0">
        <td colspan="8" style="text-align: center; padding-top: 1rem">
          <app-empty-state [text]="snippetService.getSnippet('blocks-won-component.no-blocks-won-yet')" [icon]="faCubes"></app-empty-state>
        </td>
      </tr>
      <tr *ngFor="let block of recentlyWonBlocksLimited$ | async | slice: (page - 1) * pageSize : page * pageSize; trackBy: trackBy">
        <td class="no-wrap">{{getBlockDate(block)}}</td>
        <td *ngIf="(statsService.poolConfig$ | async)?.blockExplorerBlockUrlTemplate"><a href="{{getBlockExplorerBlockLink(block)}}" target="_blank" style="color: #b45bff">{{block.height}}</a></td>
        <td *ngIf="!(statsService.poolConfig$ | async)?.blockExplorerBlockUrlTemplate">{{block.height}}</td>
        <td class="no-wrap">{{block.networkSpaceInTib ? getFormattedCapacityFromTiB(block.networkSpaceInTib) : snippetService.getSnippet('general.not-available.short')}}</td>
        <td class="no-wrap">{{block.distributionRatio}}</td>
        <td class="no-wrap"><span [class]="getEffortColor(block)">{{getBlockEffort(block)}}</span></td>
        <td class="ellipsis" *ngIf="block.winner.accountReference">
          <a class="farmer-page-link" routerLink="/farmer/{{block.winner.accountReference}}">{{block.winner.name || block.winner.payoutAddress || snippetService.getSnippet('general.unknown')}}</a>
        </td>
        <td class="ellipsis" *ngIf="!block.winner.accountReference">
          {{block.winner.name || block.winner.payoutAddress || snippetService.getSnippet('general.unknown')}}
        </td>
        <td class="no-wrap">
          <span ngbTooltip="{{ratesService.getValuesInFiatFormatted(block.reward, block.historicalRate)}}">
            {{block.reward}} {{statsService.ticker$ | async}}
          </span>
        </td>
        <td class="no-wrap" [style.padding-top]="block.distributed ? '12px' : '1px'" [style.padding-bottom]="block.distributed ? '12px' : '7px'">
          {{getBlockDistributedLabel(block)}}
          <span *ngIf="!block.distributed">({{getBlockConfirms(block)}} / {{(statsService.poolConfig$ | async)?.blockRewardDistributionDelay}})</span>
          <ngb-progressbar *ngIf="!block.distributed" [striped]="true" [animated]="true" [type]="getBlockProgressType(block)" [value]="getBlockProgress(block)"></ngb-progressbar>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ngb-pagination
  *ngIf="!limit && (recentlyWonBlocksLimitedLength$ | async) > pageSize"
  class="d-flex justify-content-center"
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="recentlyWonBlocksLimitedLength$ | async"
  [maxSize]="5"
  [rotate]="true"
  [boundaryLinks]="true">
</ngb-pagination>
